//================================================================
//  Component: Lookup React Hook Form for searching Firestore collections
//================================================================

//  Purpose: This input field allows users to search within a specific Firestore collection using the 'searcharray' field.

//  Properties:
//    - fieldId: {string} Unique ID for the field in React Hook Forms.
//    - collectionId: {string} The Firestore collection ID to search within.
//    - dataField: {string} Primary field key in the Firestore document (e.g., 'applicationname').
//    - placeholder: {string} Placeholder text displayed in the input field.
//    - required: {boolean} Indicates if the field is mandatory.
//    - register: React Hook Form register function to connect the field to form state.
//    - setValue: React Hook Form function to programmatically set a field's value.
//    - errors: Object holding any validation errors for this field.
//    - clearErrors: React Hook Form function to clear validation errors on a field.
//    - disabled (optional): {boolean} Disables the input if true.
//    - dataFieldSecondary (optional): {string} Secondary field key displayed in the dropdown list and saved to form state.
//    - dataFieldTertiary (optional): {string} Tertiary field key (not visible in the dropdown list) saved to form state, ideal for IDs, URLs, etc.
//    - additionalQueries (optional): {array} Additional Firestore query conditions.
//    - createNewHTML (optional): {HTML} Custom HTML rendered below the search results.

//  Example Usage:
//
//    <SearchExistingApplication
//      fieldId={'existingApplication'}
//      collectionId={'applications'}
//      dataField={'applicationname'}
//      placeholder={'Search application by name'}
//      required={true}
//      register={register}
//      setValue={setValue}
//      errors={errors.existingApplication}
//      clearErrors={clearErrors}
//      dataFieldSecondary={'vendor.vendorname'}
//      dataFieldTertiary={'applicationid'}
//      additionalQueries={['applicationstatus', '==', 'Active']}
//      createNewHTML={<span className='font-normal text-[#A0A0A0]'>Can't find what you're looking for? Click <a href='https://google.com' target='_blank' rel='noopener noreferrer'>here</a>.</span>}
//    ></SearchExistingApplication>    

//  IMPORTANT: Access additional document values using 'dataFieldSecondary' and 'dataFieldTertiary' props.
//
//     In the onSubmit handler, you can retrieve 'vendorname' and 'applicationid' fields:
//
//     console.log('Vendor Name:', formData['existingApplication-vendor.vendorname']);
//     console.log('Application ID:', formData['existingApplication-applicationid']);
//
//  IMPORTANT: Display two values in the dropdown list:
//  
//      For the above example, 'applicationname' (dataField) and 'vendor.vendorname' (dataFieldSecondary) will display in the dropdown,
//      while 'applicationid' (dataFieldTertiary) remains hidden in the dropdown list but is saved in the form.

//================================================================


//Libraries
import React, { useReducer } from 'react';

//Contexts

//Components

//Functions
import QueryDocument from '../../Library/QueryDocument';

//Images


export default function SearchExistingApplication({
    fieldId,
    collectionId,
    dataField,
    placeholder,
    required,
    register,
    setValue,
    errors,
    dataFieldSecondary,
    dataFieldTertiary,
    additionalQueries,
    createNewHTML,
    clearErrors,
    disabled
}) {

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store the search data
    const [searchData, setSearchData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'userInput': '',
            'queryMessage': '',
            'searchResults': []
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Helper function to get nested property value
    const getNestedField = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc?.[part], obj);
    }

    // Function used to handle search
    const handleSearch = (value) => {

        // Remove leading and trailing spaces
        value = value.trim();

        setSearchData({
            'searchResults': [],
            'userInput': value,
        })

        // Validate input
        if (value?.length === 0) {

            return setSearchData({ 'queryMessage': 'Please enter a value.' });

        }

        if (value?.length < 3) {
            return setSearchData({ 'queryMessage': 'Search requires more than two characters.' });
        }


        // Build query
        const query = additionalQueries ? [additionalQueries] : [];

        // Search by characters in email
        QueryDocument(collectionId, query).then((docs) => {

            // Filter docs by the search value
            const filteredDocs = docs.filter((doc) =>
                doc.searcharray.some((item) => item.toLowerCase() === value.toLowerCase())
            );

            setSearchData({
                'searchResults': filteredDocs,
                'queryMessage': `Showing ${filteredDocs?.length} result(s)`
            });

        }).catch((error) => {

            console.log(error);
            setSearchData({ 'queryMessage': 'Oops something went wrong!' });

        });

    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col w-full h-full relative'>

            {/* ------------------------------------------------------ */}
            {/*  Application Search bar                                */}
            {/* ------------------------------------------------------ */}

            {/* Search Bar */}
            <div className='flex flex-row items-center mb-0'>

                {/* Search Input Field */}
                <input
                    className={
                        errors ?
                            'min-w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border-[1.5px] border-solid border-[#DE0000] rounded-[5px] disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                            : searchData?.searchResults?.length > 0 ?
                                'min-w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                                :
                                'min-w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                    }
                    type='text'
                    placeholder={placeholder}
                    {...register(fieldId, {
                        required: required ? 'This field is required' : false,
                    })}
                    onChange={(e) => handleSearch(e.target.value)}
                    onPaste={(e) => handleSearch(e.clipboardData.getData('Text'))}
                    autoComplete='off'
                    disabled={disabled}
                ></input>

            </div>

            {/* Search Results */}
            {
                searchData?.userInput?.length > 0 &&
                (
                    <div className='absolute w-[450px] z-10 top-10 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>

                        {/* Results */}
                        {
                            searchData?.searchResults.map((document, index) => (
                                <div
                                    key={index}
                                    className='flex flex-row justify-between px-4 py-2 cursor-pointer rounded-sm hover:bg-[#F0F0F0]'
                                    onClick={() => {

                                        // Save values
                                        setValue(fieldId, document?.[dataField]);
                                        setValue(`${fieldId}-${dataFieldSecondary}`, document?.[dataFieldSecondary]);
                                        setValue(`${fieldId}-${dataFieldTertiary}`, document?.[dataFieldTertiary]);

                                        // Save the entire document in a hidden field
                                        setValue(`${fieldId}-fullDocument`, document);
                                        clearErrors !== undefined && clearErrors(fieldId);

                                        // Update the search results
                                        setSearchData({
                                            'searchResults': [],
                                            'userInput': '',
                                        });

                                    }}
                                >

                                    {/* User Detail */}
                                    <div className='flex flex-col'>
                                        <p className='m-0 font-medium text-[14px]'>{document?.[dataField]}</p>
                                        <p className='m-0 text-[14px]'>{getNestedField(document, dataFieldSecondary)}</p>
                                    </div>

                                </div>
                            ))
                        }

                        {/* Total */}
                        <div className='flex flex-col text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchData.queryMessage.length === 0}>
                            {searchData.queryMessage}
                            {createNewHTML}
                        </div>

                    </div>
                )
            }

            {/* Error Message */}
            {errors && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors?.message}</p>}

        </div>
    )

    //------------------------------------------------------
}
