//================================================================
//  Component: Publish to IT Store
//================================================================

//  Purpose: Publish an application to a catalogue

//  Properties:
//    - applications: an array of applications

//  PublishToITStore:
//    <PublishToITStore 
//      applications={applications}
//    ></PublishToITStore>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

//Contexts
import { SetToast } from '../../../../Library/GlobalContexts';

//Components
import SearchExistingApplication from '../../../../Components/SearchExistingApplication/SearchExistingApplication';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';
import UploadFile from '../../../../Library/UploadFile';

//Images
import Add from '../../../../Components/Images/Icon_Add_Teal.svg';
import AddDisabled from '../../../../Components/Images/Icon_AddDisabled_Teal.svg';
import DeleteIcon from '../../../../Components/Images/Icon_DeleteHover_Red.svg';
import PlaceholderIcon from '../../../../Components/Images/Image_Placeholder_Blue.png';

export default function PublishToITStore({
    applications
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    // Extract application IDs of published catalogue items from props
    const publishedCatalogueItemIds = applications.map(item => item?.application?.applicationid);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Open or Close the 'Publish to IT Store' side pane
    const [publishToITStorePaneOpen, setPublishToITStorePaneOpen] = useState(false);

    // Used to store the preview of the icon uploaded
    const [preview, setPreview] = useState(null);

    // --------------------------------------------------
    //  Form State
    // --------------------------------------------------

    // https://react-hook-form.com/get-started
    const {
        register,
        setValue,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        control,
        watch,
        trigger,
        formState: { errors },
    } = useForm({
        defaultValues: {
            supportlinks: [{ displayname: '', url: '' }]
        }
    });

    // Constants
    const selectedApplication = watch('existingApplication-fullDocument');
    const iconFile = watch('icon');

    // UseFieldArray to manage dynamic support links
    const { fields, prepend, remove } = useFieldArray({
        control,
        name: 'supportlinks'
    });

    // Used to track the number of characters in the description
    const [charCount, setCharCount] = useState(
        selectedApplication?.applicationdescription?.length || 0
      );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // This function is triggered when the user adds a new link
    const handleAddLink = async (index) => {

        // Trigger validation for the current input field
        const isValidURL = await trigger(`supportlinks.${index}.url`);

        if (isValidURL) {

            // Add a new empty link field
            prepend({ displayname: '', url: '' });

        }

    };

    //function for character count
    const handleCharacterInput = (e) => {
        let inputValue = e.target.value;
    
        // Truncate input if it exceeds 500 characters
        if (inputValue.length > 500) {
            inputValue = inputValue.substring(0, 500);
            setError("description", { 
                type: "maxLength", 
                message: "Description cannot exceed 500 characters." 
            }); // Set an error in react-hook-form
        } else {
            clearErrors("description"); // Clear the error when within limit
        }
    
        setCharCount(inputValue.length);
        setValue("description", inputValue); // Update value in react-hook-form
    };  

    // Submit Handler
    const onSubmit = (formData) => {

        // Open the toast
        setToast({
            type: 'pending',
            message: 'Preparing to publish to IT Store...',
        });

        // Accessing existingApplication-fullDocument safely
        const selectedApplication = formData['existingApplication-fullDocument'] || {};

        // Filtering out empty support links
        const supportLinks = formData.supportlinks?.filter(link => link.displayname !== '' && link.url !== '')

        // Constructing the document id
        const documentId = `item-${Date.now()}`;

        // Get the icon file from the form data
        const iconFile = formData.icon[0];

        // Constructing the document object
        const documentObject = {
            itemid: documentId,
            itemtype: 'Application',
            name: selectedApplication.applicationname || '',
            description: formData.description || '',
            iconurl: '',
            application: {
                applicationid: selectedApplication.applicationid || '',
                applicationname: selectedApplication.applicationname || '',
            },
            vendor: selectedApplication.vendor || { vendorid: '', vendorname: '', tradingas: '' },
            supportlinks: supportLinks,
            tags: {
                businessunit: selectedApplication?.businessunit || [],
                region: selectedApplication?.region || [],
                applicationcategories: selectedApplication?.applicationcategories || [],
            },
            pricing: {
                amount: formData.amount || '',
                frequency: 'Yearly',                          // Hardcoded to 'Yearly'
                currency: formData.currency || ''
            },
            projectcode: selectedApplication.projectcode || '',
            tasknumber: selectedApplication.tasknumber || '',
            expendituretype: selectedApplication.expendituretype || '',
            licensingpools: [
                {
                    poolname: 'Group - IT',
                    poolsize: 25,
                }
            ],
            licensemanagement: {
                queueid: '',
                queuetype: '',
                queuename: ''
            },
            accessmanagement: {
                queueid: '',
                queuetype: '',
                queuename: ''
            },
            support: {
                queueid: '',
                queuetype: '',
                queuename: ''
            },
            subscribed: [],
            unsubscribed: []
        };

        // Constructing the icon folder path
        const iconFolderPath = `catalogueitems/${documentId}`;

        // Upload the icon file and write to Firestore
        UploadFile(`${iconFolderPath}`, iconFile)
            .then((iconUrl) => {
                documentObject.iconurl = iconUrl;  // Set the uploaded icon URL

                // Write to Firestore
                return WriteDocument('catalogueitems', documentObject.itemid, documentObject, false);
            })
            .then(() => {
                // Reset form & close side pane
                setPublishToITStorePaneOpen(false);
                setCharCount(0);
                reset();

                // Show Success Toast
                setToast({
                    type: 'success',
                    message: (
                        <>
                            Published.
                            {/* <a href={`https://inventory.store.lendlease.com/inventory/catalogue?view=applications&applicationid=${documentObject?.application?.applicationid}`} target='_blank' rel='noopener noreferrer'>{documentObject?.name}</a> */}
                        </>
                    )
                });
            })
            .catch((error) => {
                console.error('Error occurred:', error);
                setPublishToITStorePaneOpen(false);
                setCharCount(0);
                reset();

                // Set error on toast
                setToast({
                    type: 'error',
                    message: error.message.includes('Icon upload failed') ? 'Failed to upload the icon.' : 'Failed to publish',
                });
            });
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Purpose - Used to check if the application has been published to IT Store AND set the description field
    useEffect(() => {

        // Set the initial value for the description field
        if (selectedApplication?.applicationdescription) {
            setValue('description', selectedApplication.applicationdescription);
            setCharCount(selectedApplication.applicationdescription.length);
        }

        // display toast messaege if it is alredy published to IT Store
        if (publishedCatalogueItemIds.includes(selectedApplication?.applicationid)) {
            setToast({
                type: 'error',
                message: 'This application is already published to IT Store',
            });
            setCharCount(0);
            reset();
        }

        // eslint-disable-next-line
    }, [selectedApplication, setValue]);

    // Purpose - Used to check if there is a icon uploaded and update the preview
    useEffect(() => {
        if (iconFile && iconFile[0]) {
          const isValidType = ['image/jpeg', 'image/png'].includes(iconFile[0].type);
      
          // Trigger validation when a file is added or changed
          trigger('icon');
      
          if (isValidType) {
            // Convert file to Base64
            const reader = new FileReader();
            reader.onloadend = () => {
              setPreview(reader.result); // Base64 string
            };
            reader.readAsDataURL(iconFile[0]);
          } else {
            // Reset preview if file type is invalid
            setPreview(null);
          }
        } else {
          // Reset preview if no file is selected
          setPreview(null);
        }
      }, [iconFile, trigger]);      

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    //======================================================
    //  Publish to IT Store Button
    //======================================================

    if (publishToITStorePaneOpen === false) return (

        <button className='Primary-Button whitespace-nowrap' onClick={() => setPublishToITStorePaneOpen(true)}>
            <strong>+</strong> Publish to IT Store
        </button>

    );

    //======================================================
    //  Publish to IT Store Side Pane
    //======================================================

    return (
        <>

            {/* ======== Keeps the button is visible during slide animation ========= */}
            <button className='Primary-Button whitespace-nowrap' onClick={() => setPublishToITStorePaneOpen(true)}>
                <strong>+</strong> Publish to IT Store
            </button>

            {/* ==================== Side Pane ==================== */}
            <div className='Pane-Background'>
                <dialog className='Pane-Container'>

                    {/*  ========= Publish to IT Store Form * ========= */}
                    <form className='flex flex-col justify-between w-full' onSubmit={handleSubmit(onSubmit)}>

                        <div className='w-full overflow-x-auto'>

                            {/* Header */}
                            <h4> Publish to IT Store </h4>

                            {/* Select existing application  */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Select existing application  <span className='text-[#C4314B]'>*</span></label>
                                <SearchExistingApplication
                                    fieldId={'existingApplication'}
                                    collectionId={'applications'}
                                    dataField={'applicationname'}
                                    placeholder={'Search application by name'}
                                    required={true}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors.existingApplication}
                                    dataFieldSecondary={'vendor.vendorname'}
                                    additionalQueries={['applicationstatus', '==', 'Active']}
                                    createNewHTML={
                                        <span className='font-normal text-[#A0A0A0]'>
                                            No results found.
                                        </span>
                                    }
                                    clearErrors={clearErrors}
                                />
                            </div>

                            {/*  ========= Application Name * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Application Name</label>
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    placeholder='Enter application name'
                                    autoComplete='no'
                                    value={selectedApplication?.applicationname || ''}
                                    disabled
                                    {...register(
                                        'applicationname'
                                    )
                                    }
                                ></input>
                            </div>

                            {/*  ========= Vendor Name * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Vendor Name </label>
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    placeholder='Enter vendor name'
                                    autoComplete='no'
                                    value={selectedApplication?.vendor?.vendorname || ''}
                                    disabled
                                    {...register(
                                        'vendorname'
                                    )
                                    }
                                ></input>
                            </div>

                            {/*  ========= Description * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Description <span className='text-[#C4314B]'>*</span></label>
                                <textarea
                                    className={errors.description ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                    type='text'
                                    placeholder='Enter description'
                                    autoComplete='no'
                                    defaultValue={selectedApplication?.applicationdescription || ''}
                                    onInput={handleCharacterInput}
                                    {...register(
                                        'description', {
                                        required: 'This is a required field.',
                                        maxLength: {
                                            value: 500,
                                            message: 'The description must not exceed 500 characters.',
                                        }
                                    })}
                                />
                                {errors.description && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.description?.message}</p>}
                                <p
                                    className={`font-medium text-sm mb-0 ${charCount > 500 ? 'text-[var(--darkred)]' : 'text-gray-600'
                                        }`}
                                >
                                    Character Count: {charCount} / 500
                                </p>
                            </div>

                            {/*  ========= Icon * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>Icon <span className='text-[#C4314B]'>*</span></label>

                                <div className='Image-Preview'>
                                    <img
                                        src={preview ? preview : PlaceholderIcon}
                                        alt='Uploaded icon preview'
                                        className='w-32 h-32 object-cover p-2 border border-gray-200 rounded-lg shadow'
                                    />
                                </div>
                                <div className='Attach-File-Onload-Container'>
                                    <input
                                        className={errors.icon ? 'Input-Field-Text-Error' : 'Input-Field-Text'}
                                        style={{ padding: '0px' }}
                                        accept='.png,.jpeg'
                                        type='file'
                                        {...register('icon', {
                                            required: 'File is required',
                                            validate: {
                                                fileType: (value) =>
                                                    // eslint-disable-next-line no-mixed-operators
                                                    value && ['image/jpeg', 'image/png'].includes(value[0]?.type) ||
                                                    'Only JPEG or PNG files are allowed'
                                            }
                                        })}
                                    ></input>
                                </div>
                                {errors.icon && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.icon.message}</p>}
                            </div>

                            {/*  ========= Support Links * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Support Links </label>
                                {
                                    fields.map((field, index) => (
                                        <div key={field.id}>
                                            {
                                                index === 0 ?

                                                    // Input Fields
                                                    <div>
                                                        <div className='flex flex-row gap-2 items-center'>

                                                            {/* ========= Display Name * ========= */}
                                                            <div>
                                                                <input
                                                                    className={errors.supportlinks?.[index]?.displayname ? 'Input-Field-Text-Error' : 'Input-Field-Text'}
                                                                    type='text'
                                                                    placeholder='Enter Support Display Name'
                                                                    style={{ width: '250px' }}
                                                                    autoComplete='off'
                                                                    {...register(`supportlinks.${index}.displayname`)}
                                                                ></input>
                                                                {errors.supportlinks?.[index]?.displayname && (
                                                                    <p className='font-medium text-sm text-[var(--darkred)] my-2'>
                                                                        {errors.supportlinks?.[index]?.displayname?.message}
                                                                    </p>
                                                                )}
                                                            </div>

                                                            {/* ========= Support Link URL * ========= */}
                                                            <div>
                                                                <input
                                                                    className={errors.supportlinks?.[index]?.url ? 'Input-Field-Text-Error' : 'Input-Field-Text'}
                                                                    style={{ width: '350px' }}
                                                                    type='url'
                                                                    placeholder='Enter Support Link URL'
                                                                    autoComplete='off'
                                                                    {...register(`supportlinks.${index}.url`, {
                                                                        pattern: {
                                                                            value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
                                                                            message: 'Please enter a valid URL',
                                                                        },
                                                                    })}
                                                                ></input>
                                                                {errors.supportlinks?.[index]?.url && (
                                                                    <p className='font-medium text-sm text-[var(--darkred)] my-2'>
                                                                        {errors.supportlinks?.[index]?.url?.message}
                                                                    </p>
                                                                )}
                                                            </div>

                                                            <div className='flex flex-row gap-2 align-items-center self-start'>

                                                                {/* Add button for adding a new link */}
                                                                {
                                                                    watch(`supportlinks.${index}.displayname`)?.trim() && watch(`supportlinks.${index}.url`)?.trim() ?
                                                                        <img
                                                                            className='min-w-[35px] min-h-[35px] cursor-pointer self-center'
                                                                            src={Add}
                                                                            alt='Add Icon'
                                                                            onClick={() => handleAddLink(index)}
                                                                        ></img>
                                                                        :
                                                                        <img
                                                                            className='min-w-[35px] min-h-[35px] cursor-not-allowed self-center'
                                                                            src={AddDisabled}
                                                                            alt='Blurred Icon'
                                                                        ></img>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                    :

                                                    // Labels
                                                    <div className='flex gap-2 items-center mt-2'>
                                                        <p className='whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-medium px-[15px] py-[7px] rounded-md overflow-hidden text-ellipsis w-[250px] min-h-[42px] mb-0'>
                                                            {field?.displayname}
                                                        </p>
                                                        <p className='whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-medium underline px-[15px] py-[7px] rounded-md overflow-hidden text-ellipsis w-[350px] min-h-[42px] mb-0'>
                                                            {field?.url}
                                                        </p>

                                                        {/* Remove button for each link */}
                                                        <img
                                                            className='min-w-[35px] min-h-[35px] cursor-pointer self-start'
                                                            src={DeleteIcon}
                                                            alt='Delete-Icon'
                                                            onClick={() => remove(index)}
                                                        ></img>
                                                    </div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>

                            {/*  ========= Pricing * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Pricing <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.amount ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='number'
                                    placeholder='Enter Pricing'
                                    autoComplete='no'
                                    {...register(
                                        'amount', {
                                        required: 'This is a required field.',
                                        min: {
                                            value: 0,
                                            message: 'Pricing amount cannot be negative.'
                                        }
                                    })}
                                ></input>
                                {errors.amount && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.amount?.message}</p>}
                            </div>

                            {/*  ========= Currency * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> Currency <span className='text-[#C4314B]'>*</span></label>
                                <select
                                    className={errors.currency ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                                    {...register(
                                        'currency', {
                                        required: 'This is a required field.'
                                    })
                                    } >
                                    <option value='AUD'>AUD</option>
                                    <option value='USD'>USD</option>
                                </select>
                            </div>

                        </div>

                        {/*  ========= Button Container ========= */}
                        <div className='flex flex-row gap-2 border-solid border-t-2 pt-3 mt-3'>

                            {/* Publish */}
                            <button className='Primary-Button whitespace-nowrap' disabled={false} type='submit' value='submit'>
                                Publish
                            </button>

                            {/* Cancel */}
                            <button className='Secondary-Button whitespace-nowrap' onClick={() => {

                                // Close Side Pane
                                reset();
                                setCharCount(0);
                                setPublishToITStorePaneOpen(false);

                            }}>
                                Cancel
                            </button>

                        </div>

                    </form>

                    {errors.applications && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.applications?.message}</p>}

                </dialog>
            </div>
        </>
    );
}

