//================================================================
//  Page: Agreement
//================================================================

//  Purpose: This page shows the current Agreement

//  Example:
//    <Agreement></Agreement>    

//================================================================


// Libraries
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Contexts

// Components
import PageComponent from '../../../../Components/PageComponent/PageComponent';
import TabView from '../../../../Components/TabView/TabView';
import BreadCrumbs from '../../../../Components/Breadcrumbs/BreadCrumbs';
import StatusLabel from '../../../../Components/StatusLabel/StatusLabel';

// Tabs
import Overview from './Tabs/Overview';
import Cyber from './Tabs/Cyber';
import Terms from './Tabs/Terms';
import Contracts from './Tabs/Contracts';

// Functions
import DocumentListener from '../../../../Library/DocumentListener';

// Images

// CSS
import './Agreement.css';

 
export default function Agreement() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------
  
    const routerUseLocation = useLocation();
    const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------
  
    // Used to store the page status > 'pending', 'onload'
    const [pageStatus, setPageStatus] = useState('pending');

    // Used to store the agreement document
    const [agreementDoc, setAgreementDoc] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Query Listener
    // - Get Firestore document
    useEffect(() => {

      const agreementid = params.id;

      if (agreementid === '') return;
      if (agreementid === null) return;

      function onLoadChange(document){  

        setAgreementDoc(document);
        setPageStatus('onload');

      }
      
      function onError(error){

        console.log(error);
        setPageStatus('error-fatal');
      
      }
      
      const unsubscribe = DocumentListener('agreements', agreementid, onLoadChange, onLoadChange, onError);
      
      return () =>{
        unsubscribe();
      };
        
    // eslint-disable-next-line
    }, [pageStatus]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        pageStatus={pageStatus}
        header={
          <div className='Page-Header-Container'>

            <BreadCrumbs
              rootLinkName='Agreements'                      
              rootLinkRoute={`/${routerUseLocation.pathname.split('/')[1]}?view=agreements`}
              childLinkName={agreementDoc?.poanumber}
            ></BreadCrumbs>   

            <div className='flex flex-row gap-4'>
              <p className='font-medium text-[20px] mb-0'>{agreementDoc?.vendor.vendorname}</p>
              <StatusLabel status={agreementDoc?.status}></StatusLabel>
            </div>

          </div>
        }
        body={
          <div>

            <TabView
              defaultView={1}

              //------------------------------------------------------
              // Overview
              //------------------------------------------------------

              oneTitle={'Overview'}
              oneRoute ={`/inventory/agreement/${params.id}?view=overview`}
              oneContent={
                <Overview></Overview>
              }

              //------------------------------------------------------
              // Cyber
              //------------------------------------------------------

              twoTitle={'Cyber'}
              twoRoute ={`/inventory/agreement/${params.id}?view=cyber`}
              twoContent={
                <Cyber></Cyber>
              }

              //------------------------------------------------------
              // Terms
              //------------------------------------------------------

              threeTitle={'Terms'}
              threeRoute ={`/inventory/agreement/${params.id}?view=terms`}
              threeContent={
                <Terms></Terms>
              }

              //------------------------------------------------------
              // Contracts
              //------------------------------------------------------

              fourTitle={'Contracts'}
              fourRoute ={`/inventory/agreement/${params.id}?view=contracts`}
              fourContent={
                <Contracts></Contracts>
              }


            ></TabView>    

          </div>
        }
      ></PageComponent> 
    )
}
