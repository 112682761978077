//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// Contexts
import { GetUser } from '../../Library/GlobalContexts.js';

//Components
import Logout from '../../Library/Logout';
import NavItem from './NavItem';

//Images
import LLLogo from '../Images/Icon_LLLogo_Green.svg';
import WorkspaceLogo from '../Images/Icon_WorkspaceI_Black.svg';
import Expand from '../Images/Icon_Expand_Grey.svg';

//CSS


export default function Navbar() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const routerUseLocation = useLocation();

  //------------------------------------------------------
  //  Define useStates
  //------------------------------------------------------

    // Used to change between > 'pending', 'success'
    const [pageStatus, setPageStatus] = useState('pending');

    // If the menu is open or closed
    const [menuOpen, setMenuOpen] = useState(false);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Returns the style of each component
    function changeStyleHandler(link, textColour) {

      if (textColour === undefined) {

        textColour = 'text-black'

      }

      //Exception for the default route '/' > We want to the home icon to be selected
      if(routerUseLocation.pathname === '/' && link.toLowerCase() === '/inventory?view=agreements') {

        //Update the style to clicked
        return `cursor-pointer border-b-solid border-b-[#E9EBED] border-b-2 px-[10px] py-[10px] no-underline ${textColour}`;

      // Clicked item --> include child pages --> '/home' & '/home/child' are treated the same!
      } else if (routerUseLocation.pathname.toLowerCase().includes(link.split('?')[0].toLowerCase())){
    
        //Update the style to clicked
        return `cursor-pointer font-medium px-[10px] py-[10px] no-underline ${textColour}`;

      //Update the style to NOT clicked
      } else {
        
        return `cursor-pointer p-[10px] no-underline ${textColour}`;

      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // OnLoad
    useEffect(() => {

      if (getUser?.roles === undefined) return setPageStatus('success');

    },[pageStatus, getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div className='flex flex-row items-center justify-between bg-[#141C25] text-[white] px-[25px] py-0 h-[64px]'>

        {/* ---------------------------------------------------- */}
        {/*  Logo                                                */}
        {/* ---------------------------------------------------- */}

        <div className='flex flex-row items-center justify-between p-0'>
    
          {/* Logo */}
          <div className='flex items-center gap-3 h-full min-w-[235px] p-0'>

            <NavLink to='/inventory?view=agreements'>
              <img className='cursor-pointer' src={LLLogo} alt='Lendlease Logo' width='48px' height='35px'></img>
            </NavLink>

            <label className='h-[40px] content-center font-medium text-lg'> Lendlease Portal </label>

            {/* Divider */}
            <hr className='w-[2px] h-[40px] border-0 bg-[#35404B] p-0'></hr>

          </div>

          {/* ---------------------------------------------------- */}
          {/*  Links                                               */}
          {/* ---------------------------------------------------- */}

          <div className='flex flex-row m-0 p-0'>

            {/* Inventory */}
            <NavItem style={changeStyleHandler('/inventory?view=agreements', 'text-white')} link={'/inventory?view=agreements'} title={'Inventory'}></NavItem>

            {/* Catalogue */}
            {/* <NavItem style={changeStyleHandler('/catalogue?view=applications', 'text-white')} link={'/catalogue?view=applications'} title={'Catalogue'}></NavItem> */}

          </div>

        </div>

        {/* ---------------------------------------------------- */}
        {/*  Workspace and Profile                               */}
        {/* ---------------------------------------------------- */}

        <div className='flex flex-row items-center gap-3 p-0'>

          {/* Workspace */}
          <div className='flex flex-row gap-2 items-center bg-[rgba(255,_255,_255,_0.10)] h-[40px] content-center text-lg px-2 rounded-sm' onClick={() => setMenuOpen(!menuOpen)}>
            
            <div>
              <img className='p-0' src={WorkspaceLogo} alt='initials'></img>
            </div>

            <div className='flex flex-col gap-0 m-0 p-0 font-light text-[11px] leading-4'>
              WORKSPACE
              <br></br>
              <b className='font-medium text-[13px]'>INVENTORY</b>
            </div>

            <img className='ml-[40px]' src={Expand} alt='expand'></img>

          </div>


          {/* Divider */}
          <hr className='w-[2px] h-[40px] border-0 bg-[#35404B] p-0'></hr>

          {/* Avatar / Profile icon */}
          <label className='cursor-pointer bg-[#8ECDCA] text-[#141c25ec] p-[7px] rounded-full text-md' onClick={() => setMenuOpen(!menuOpen)}>
            {getUser.givenname[0]}{getUser.surname[0]}
          </label>

          {/* Sign out in Menu */}
          <div hidden={!menuOpen} className='flex flex-column absolute top-[64px] right-[0px] bg-[#141c25ec] w-[130px] p-2 rounded-b'>

            {/* Log out Button */}
            <div className='flex flex-row-reverse text-center p-1'>
              <Logout></Logout>
            </div>
            
          </div>

        </div>

      </div>

    )

}
