//================================================================
//  Tab: Agreements
//================================================================

//  Purpose: This tab displays all 'Agreements'

//  Example:
//    <Agreements></Agreements>    

//================================================================

// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { SetSortFilters } from '../../../Library/GlobalContexts';

// Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import AddAgreement from './SidePanes/AddAgreement';
import EditApplicationsInAgreement from './SidePanes/EditApplicationsInAgreement';
import TableHeader from '../../../Components/SortFilter/TableHeader';
import FilterTags from '../../../Components/SortFilter/FilterTags';
import FilterByPane from '../../../Components/SortFilter/FilterByPane';

// Functions
import QueryListener from '../../../Library/QueryListener';
import GetCollection from '../../../Library/GetCollection';

// Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';
import EditIcon from '../../../Components/Images/Icon_Edit_Teal.svg';


export default function Agreements() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const navigate = useNavigate();

  const setSortFilters = useContext(SetSortFilters);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Search Input
  const [searchInput, setSearchInput] = useState('');

  // Current Filter > 'Active', 'Draft', 'Cancelled', 'Expired' & 'All'
  const [currentFilter, setCurrentFilter] = useState('Active');

  // List of Applications attached to the agreement
  const [applications, setApplications] = useState([]);

  // Stores all agreements
  const [agreements, setAgreements] = useState([]);
  const [agreementsFiltered, setAgreementsFiltered] = useState([]);

  // Open or Close the 'Edit Applications in Agreement' side pane
  const [agreementPane, setAgreementPane] = useState(undefined);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Gets related applications from applications table
  function getApplications(agreementId) {

    const appList = applications?.filter(application => application?.agreements?.some(agreement => agreement?.agreementid === agreementId));
    
    return appList;

  };

  // Handles search function
  function handleSearch(value) {

    // Clear ALL filters & sorting
    if (value.length > 0 && value.length < 2) {

      setSortFilters({

        // Sorting
        'sortBy': '', // vendor.vendorname
        'sortDirection': '', // ascending & descending
        'sortDataType': '', // '' or 'number'

        // Filtering (Map of objects)
        'filters': {},

        // Sidepane for selecting filterby content
        'filterByPane': {
          'columnname': '', // Vendor Name
          'columnid': '', // vendor.vendorname
        }

      });

    }

    setSearchInput(value);

    // Reset the Filter
    if (value.length === 0) {

      setAgreementsFiltered(agreements);

    };

    // Search Filter
    //  1. Agreement Id
    //  2. Vendors Name
    const searchResults = agreements.filter((object) =>
      object?.poanumber?.toLowerCase().includes(value.toLowerCase()) ||
      object?.vendor?.vendorname?.toLowerCase().includes(value.toLowerCase())
    );

    // Save the filtered search results to the useState
    setAgreementsFiltered(searchResults);

  };

  // Create data format for CSV Export
  function getDataObject(agreements) {
    const data = [];

    agreements.forEach((agreement) => {

      data.push({
        'PoA Number': agreement?.poanumber,
        'Vendor': agreement?.vendor?.vendorname,
        'Start Date': agreement?.agreementstartdate,
        'End Date': agreement?.agreementenddate,
        'Execution Date': agreement?.executiondate,
        'Total Agreement Amount': `$${agreement?.totalagreementamount} (${agreement?.contractcurrency?.length > 0 && agreement?.contractcurrency})`,
        'Status': agreement?.status,
        'Applications': agreement?.applications?.map(app => app.applicationname),
      })
    })

    return data;
  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all agreements
  useEffect(() => {

    if (currentFilter === undefined) return;

    function onLoad(documents) {

      setAgreements(documents);
      setAgreementsFiltered(documents);
      setSearchInput('');

      // Get all Applications documents
      GetCollection('applications')
      .then((documents) => {

        setApplications(documents)

        // Load the page
        setPageStatus('onload');

      })
      .catch((error) => {

        console.log('error', error);

        setPageStatus('error-fatal');

      })

    }

    function onChange(documents) {

      setAgreements(documents);
      setSearchInput('');

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = QueryListener('agreements', currentFilter === 'All' ? [] : [['status', '==', currentFilter]], onLoad, onChange, onError, ['poanumber', 'asc']);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [currentFilter, pageStatus]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='flex flex-col px-[15px] gap-[20px] w-[calc(100vw-40px)] max-h-[calc(100vh-220px)] overflow-hidden'>

          {/* ----------------------------------- */}
          {/*  Status & Search                    */}
          {/* ----------------------------------- */}

          <div className='flex flex-row justify-between'>

            {/* ======== Status (Buttons) ========== */}
            <div className='flex flex-row items-center gap-2'>

              {/* Active */}
              <button className={currentFilter === 'Active' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Active')}>
                Active
              </button>

              {/* Draft */}
              <button className={currentFilter === 'Draft' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Draft')}>
                Draft
              </button>

              {/* Expired */}
              <button className={currentFilter === 'Expired' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Expired')}>
                Expired
              </button>

              {/* Cancelled */}
              <button className={currentFilter === 'Cancelled' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Cancelled')}>
                Cancelled
              </button>

              {/* All */}
              <button className={currentFilter === 'All' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('All')}>
                All
              </button>

            </div>

            {/* ======== Search, Add Agreement & Download ========== */}

            <div className='flex flex-row justify-end items-center gap-2'>

              {/* ======== Search Bar ======== */}
              <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                <label htmlFor='searchInput'>
                  <input
                    id='searchInput'
                    className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                    type='text'
                    placeholder='Search'
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchInput}
                    autoComplete='no'
                  ></input>
                </label>
              </div>

              <AddAgreement></AddAgreement>

              {/* Download Report */}
              <ExportToCSV
                filename={`Agreements-Triage-${Date.now()}`}
                data={getDataObject(agreementsFiltered)}
              ></ExportToCSV>

            </div>

          </div>

          {/* ----------------------------------- */}
          {/*  Table Container                    */}
          {/* ----------------------------------- */}

          <FilterTags
            tableArray={agreements}
            setFilteredTableArray={setAgreementsFiltered}
          ></FilterTags>

          <div className='Table-Container flex flex-col w-full h-full overflow-hidden'>

            {/* ======== Header ========== */}
            <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

              <label className='font-semibold self-center'>
                Total: {agreementsFiltered.length} of {agreements.length}
              </label>

            </div>

            {/* ======== Table Scrollable ========== */}
            <div className='w-full overflow-auto min-h-[350px]'>

              <FilterByPane
                tableArray={agreements}
              ></FilterByPane>

              <table className='w-full'>
                <thead>
                  <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2 z-10 box-border overflow-visible'>

                    {/* PoA Number */}
                    <TableHeader
                      column='PoA Number'
                      styleInput={{ minWidth: '120px' }}
                      objectKey='poanumber'
                      sticky={true}
                      setSearchInput={setSearchInput}
                    ></TableHeader>

                    {/* Vendor */}
                    <TableHeader
                      column='Vendor'
                      objectKey='vendor.vendorname'
                      setSearchInput={setSearchInput}
                    ></TableHeader>

                    {/* Applications */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>
                      Applications
                    </th>

                    {/* Start Date */}
                    <TableHeader
                      column='Start Date'
                      objectKey='agreementstartdate'
                    ></TableHeader>

                    {/* End Date */}
                    <TableHeader
                      column='End Date'
                      objectKey='agreementenddate'
                    ></TableHeader>

                    {/* Execution Date */}
                    <TableHeader
                      column='Execution Date'
                      objectKey='executiondate'
                    ></TableHeader>

                    {/* Total Agreement Amount */}
                    <TableHeader
                      column='Total Agreement Amount'
                      objectKey='totalagreementamount'
                      dataType='number'
                      setSearchInput={setSearchInput}
                    ></TableHeader>

                    {/* Estimated Total Value */}
                    <TableHeader
                      column='Estimated Total Value'
                      objectKey='esttotalcontractvalue'
                      dataType='number'
                      setSearchInput={setSearchInput}
                    ></TableHeader>

                    {/* Status */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>
                      Status
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {
                    // No agreements found
                    agreementsFiltered.length === 0 ?
                      (
                        <tr>
                          <td colSpan={9}>
                            <div className='text-center p-[2%]'>
                              No agreements found.
                            </div>
                          </td>
                        </tr>
                      )
                      :
                      (
                        // Map in each commercial agreement
                        agreementsFiltered.map((agreement, index) => (
                          <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>

                            {/* PoA Number */}
                            <td className='py-[15px] px-[20px] font-semibold left-0 drop-shadow-md bg-white sticky cursor-pointer underline' onClick={() => navigate(`/inventory/agreement/${agreement.agreementid}?view=overview`)}>
                              {agreement?.poanumber}
                            </td>

                            {/* Vendor Name */}
                            <td className='py-[15px] px-[20px]'>
                              {agreement?.vendor?.vendorname}
                            </td>

                            {/* Applications */}
                            <td className='py-[15px] px-[5px] w-[250px] whitespace-nowrap'>

                              {
                                // MORE than 2 Applications
                                getApplications(agreement?.agreementid).length > 2 ?
                                  (
                                    <div className='flex flex-row justify-between px-2'>

                                      {/* App List */}
                                      <div className='flex flex-col gap-1'>

                                        <label 
                                          className='max-w-[150px] whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-semibold underline cursor-pointer px-[8px] py-[6px] rounded-md overflow-hidden text-ellipsis w-fit' 
                                          onClick={() => navigate(`/inventory/application/${getApplications(agreement?.agreementid)[0]?.applicationid}?view=overview`)}
                                        >
                                          {getApplications(agreement?.agreementid)[0]?.applicationname}
                                        </label>
                                        
                                        <label 
                                          className='max-w-[150px] whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-semibold underline cursor-pointer px-[8px] py-[6px] rounded-md overflow-hidden text-ellipsis w-fit' 
                                          onClick={() => navigate(`/inventory/application/${getApplications(agreement?.agreementid)[1]?.applicationid}?view=overview`)}
                                        >
                                          {getApplications(agreement?.agreementid)[1]?.applicationname}
                                        </label>

                                      </div>

                                      {/* Edit Icon */}
                                      <div className=' flex flex-col justify-between'>
                                        <img className='w-[20px] self-end cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setAgreementPane(agreement)}></img>
                                        <label className='text-right font-semibold mt-[18px] text-[#212529]'>... 2 of {getApplications(agreement?.agreementid).length}</label>
                                      </div>

                                    </div>
                                  )
                                  : // Less than 2 Applications
                                  getApplications(agreement?.agreementid).length > 0 ? (
                                    <div className='flex flex-row justify-between px-2'>
                                      <div className='flex flex-col gap-1'>

                                        <label 
                                          className='max-w-[150px] whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-semibold underline cursor-pointer px-[8px] py-[6px] rounded-md overflow-hidden text-ellipsis w-fit' 
                                          onClick={() => navigate(`/inventory/application/${getApplications(agreement?.agreementid)[0]?.applicationid}?view=overview`)}
                                        >
                                          {getApplications(agreement?.agreementid)[0]?.applicationname}
                                        </label>
                                        
                                        <label 
                                          className='max-w-[150px] whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-semibold underline cursor-pointer px-[8px] py-[6px] rounded-md overflow-hidden text-ellipsis w-fit' 
                                          hidden={getApplications(agreement?.agreementid)?.length < 2} 
                                          onClick={() => navigate(`/inventory/application/${getApplications(agreement?.agreementid)[0].applicationid}?view=overview`)}
                                        >
                                          {getApplications(agreement?.agreementid)[1]?.applicationname}
                                        </label>

                                      </div>
                                      <img className='w-[20px] self-start cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setAgreementPane(agreement)}></img>
                                    </div>
                                  )
                                    : // No Applications -> Just show edit icon
                                    (
                                      <div className='flex flex-row justify-between px-2'>
                                        <label className='bg-[#F5F5F5] round p-[6px]'>N/A</label>
                                        <div className='flex justify-end'>
                                          <img className='w-[20px] self-start cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setAgreementPane(agreement)}></img>
                                        </div>
                                      </div>
                                    )

                              }
                            </td>

                            {/* Agreement Start Date */}
                            <td className='py-[15px] px-[20px]'>
                              {agreement?.agreementstartdate}
                            </td>

                            {/* Agreement End Date */}
                            <td className='py-[15px] px-[20px]'>
                              {agreement?.agreementenddate}
                            </td>

                            {/* Execution Date */}
                            <td className='py-[15px] px-[20px]'>
                              {agreement?.executiondate}
                            </td>

                            {/* Total Agreement Amount */}
                            <td className='py-[15px] px-[20px]'>
                              {
                                agreement?.totalagreementamount?.length > 0 ?
                                  <>
                                    ${agreement?.totalagreementamount} {agreement?.contractcurrency?.length > 0 && `(${agreement?.contractcurrency})`}
                                  </>
                                  :
                                  <>
                                    -
                                  </>
                              }
                            </td>

                            {/* Estimated Total Value */}
                            <td className='py-[15px] px-[20px]'>
                              {
                                agreement?.esttotalcontractvalue?.length > 0 ?
                                  <>
                                    ${agreement?.esttotalcontractvalue} {agreement?.contractcurrency?.length > 0 && `(${agreement?.contractcurrency})`}
                                  </>
                                  :
                                  <>
                                    -
                                  </>
                              }
                            </td>

                            {/* Status */}
                            <td className='py-[15px] px-[20px]'>
                              <StatusLabel status={agreement?.status}></StatusLabel>
                            </td>

                          </tr>
                        ))
                      )
                  }
                </tbody>
              </table>

            </div>

          </div>

          {/* ----------------------------------- */}
          {/*  Side Panes                         */}
          {/* ----------------------------------- */}

          <EditApplicationsInAgreement agreement={agreementPane} setAgreementPane={setAgreementPane} applications={getApplications(agreementPane?.agreementid)} setPageStatus={setPageStatus}></EditApplicationsInAgreement>

        </div>
      }
    ></PageComponent>
  )
}
