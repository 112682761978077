//================================================================
//  Component: Add Application
//================================================================

//  Purpose: Add a new application to the applications collection

//  Properties:
//    - None

//  AddApplication:
//    <AddApplication></AddApplication>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//Contexts
import { GetUser, SetToast } from '../../../../Library/GlobalContexts';

//Components
import LookUpRHF from '../../../../Components/LookUpRHF/LookUpRHF';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';
import getCollection from '../../../../Library/GetCollection';

//Images
import IconSearch from '../../../../Components/Images/Icon_Search_Grey.svg';


export default function AddApplication() {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Open or Close the 'Add Application' side pane
    const [addApplication, setAddApplication] = useState(false);

    // List of applications in Firestore
    const [allAgreements, setAllAgreements] = useState([]);
    const [agreementsFiltered, setAgreementsFiltered] = useState([]);

    // --------------------------------------------------
    //  Form State
    // --------------------------------------------------

    // https://react-hook-form.com/get-started
    const {
        register,
        setValue,
        handleSubmit,
        reset,
        clearErrors,
        formState: { isSubmitSuccessful, errors },
    } = useForm();

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

        // Reset the Filter
        if (value.length === 0) {

            setAgreementsFiltered(allAgreements);

        };

        setAgreementsFiltered(

            allAgreements.filter((obj) => obj.vendor?.vendorname?.toLowerCase().includes(value?.toLowerCase()))

        );

    };

    // Submit Handler
    const onSubmit = (formData) => {

        // Open the toast
        setToast({
            'type': 'pending',
            'message': 'Preparing application',
        });

        // ============================
        //  Find 'agreementid' with PoA
        // ============================

        const agreements = [];

        if (Array.isArray(formData.agreements)) {

            formData.agreements?.forEach((agreementid) => {

                const findAgreement = allAgreements.find((obj) => obj.agreementid === agreementid);

                agreements.push({
                    'agreementid': agreementid,
                    'poanumber': findAgreement.poanumber,
                });

            });

        } 

        // ============================
        // Create a searcharray for lookup fields
        // ============================

        const searchArray = [];
        let previousValue = '';
        [...formData.applicationname]?.forEach((value, index) => {

            // Skip the first character
            if (index === 0) {

                previousValue = value;
                return searchArray.push(value.toLowerCase());

            }

            // Create an array of each character
            searchArray.push(`${previousValue}${value}`.toLowerCase());
            previousValue = `${previousValue}${value}`.toLowerCase();

        });

        // ============================
        // Firestore Doc
        // ============================

        const documentObject = {
            'applicationid': `${btoa(formData.applicationname)}-${Date.now()}`,
            'requestid': [],
            'applicationstatus': formData.applicationstatus,

            'createdby': getUser.emailaddress, // Email Address
            'createdbyname': `${getUser.givenname} ${getUser.surname}`, // Full Name (i.e. Nowshin Hassan)
            'createddate': new Date(),
            'lastmodifieddate': '',
            'lastmodifiedby': '',

            'agreements': agreements,
            'applicationname': formData.applicationname,
            'searcharray': searchArray,
            'applicationdescription': formData.applicationdescription,
            'vendor': {
                'vendorname': formData.vendorname,
                'vendorid': formData['vendorname-vendorid'],
            },
            'decommissiondate': '',
            'itowner': [
                formData.itowner
            ],
            'businessowner': [
                formData.businessowner
            ],
            'icon': '',
            'defaultapp': [],
            'usedby': [],
            'subscribed': [],
            'unsubscribed': [],
            'nooflicensespurchased': '0',
            'type': '',
            'deploymentname': '',
            'deploymenttype': '',
            'capabilityfamily': [],
            'capabilitylevel3': '',
            'supportedby': formData.supportedby,
            'supportlevelinternal': [],
            'supportlevelexternal': [],
            'ssoimplemented': '',
            'mfaimplemented': '',
            'typeofcost': '',
            'budgetowner': [],
            'projectcode': '',
            'tasknumber': '',
            'expendituretype': '',
            'licencetype': '',
            'costallocationcurrency': '',
            'bcp': '',
            'rpo': '',
            'rto': '',
            'linktodr': '',
            'primarybeneficiary': '',
            'businessunit': [],
            'region': [],
            'applicationcategories': [],
        };

        WriteDocument('applications', documentObject.applicationid, documentObject, false)
        .then(() => {

            // Reset form & close side pane
            setAddApplication(false);

            // Show Success Toast
            setToast(
                {
                    'type': 'success',
                    'message': 
                        <>
                            Application added. <a href={`https://inventory.store.lendlease.com/inventory/application/${documentObject?.applicationid}?view=overview`} target='_blank' rel='noopener noreferrer'>{documentObject?.applicationname}</a>
                        </>
                }
            );

        })
        .catch((error) => {

            console.log('error', error);

            // Reset form & close side pane
            setAddApplication(false);
            
            // Set error on toast
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to create application',
            });
            

        });
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    useEffect(() => {

        if (isSubmitSuccessful) {
            reset();
        };

        getCollection('agreements')
        .then((documents) => {

            setAllAgreements(documents);
            setAgreementsFiltered(documents);

        })
        .catch((error) => {

            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to get agreements',
            });

        });

    // eslint-disable-next-line 
    }, [isSubmitSuccessful, reset]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (addApplication === false) return (

        <button className='Primary-Button whitespace-nowrap' onClick={() => setAddApplication(true)}>
            <strong>+</strong> Add Application
        </button>

    );

    return (
        <>

            {/* ======== Keeps the button is visible during slide animation ========= */}
            <button className='Primary-Button whitespace-nowrap' onClick={() => setAddApplication(true)}>
                <strong>+</strong> Add Application
            </button>

            {/* =========================================================== */}
            {/*  Add Application Pane                                         */}
            {/* =========================================================== */}

            <div className='Pane-Background'>
                <dialog className='Pane-Container'>

                    {/*  ========= Add Application Form * ========= */}
                    <form className='flex flex-col justify-between w-full' onSubmit={handleSubmit(onSubmit)}>

                        <div className='w-full overflow-x-auto'>

                            {/* Header */}
                            <h4> Add Application </h4>


                            {/*  ========= 1. Application Name * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>1. Application Name <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.applicationname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='Enter application name'
                                    autoComplete='no'
                                    {...register(
                                        'applicationname', {
                                        required: 'This is a required field.',
                                        maxLength: { value: 100, message: 'Name cannot exceed 100 characters' },
                                    })}
                                />
                                {errors.applicationname && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.applicationname?.message}</p>}
                            </div>

                            {/*  ========= 2. Application Description * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>2. Application Description <span className='text-[#C4314B]'>*</span></label>
                                <textarea
                                    className={errors.applicationdescription ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                    type='text'
                                    placeholder='Enter description'
                                    autoComplete='no'
                                    {...register(
                                        'applicationdescription', {
                                        required: 'This is a required field.',
                                        maxLength: 500,
                                    })}
                                />
                                {errors.applicationdescription && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.applicationdescription?.message}</p>}
                            </div>

                            {/*  ========= 3. Application Status * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> 3. Application Status <span className='text-[#C4314B]'>*</span></label>
                                <select
                                    className='Input-Field-Select'
                                    {...register(
                                        'applicationstatus', {
                                        required: 'This is a required field.'
                                    })
                                    } >
                                    <option value='Active'>Active</option>
                                    <option value='Divest'>Divest</option>
                                    <option value='Dormant'>Dormant</option>
                                    <option value='Archived'>Archived</option>
                                    <option value='Decommissioned'>Decommissioned</option>
                                </select>
                            </div>

                            {/*  ========= 4. Vendor Name * ========= */}
                            <div className='FormComponent-Row'>

                                <label className='font-medium'>4. Vendor Name <span className='text-[#C4314B]'>*</span></label>
                                <LookUpRHF
                                    fieldId={'vendorname'}
                                    collectionId={'vendors'}
                                    dataField={'vendorname'}
                                    placeholder={'Search vendor by name'}
                                    required={true}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors.vendorname}
                                    dataFieldSecondary={'tradingas'}
                                    dataFieldTertiary={'vendorid'}
                                    additionalQueries={['vendorstatus', '==', 'Active']}
                                    createNewHTML={<span className='font-normal text-[#A0A0A0]'>Can't find what you're looking for? Click <a href='http://inventory.store.lendlease.com/inventory?view=vendors' target='_blank' rel='noopener noreferrer'>here</a>.</span>}
                                    clearErrors={clearErrors}
                                ></LookUpRHF>

                            </div>

                            {/*  ========= 5. Business Owner * ========= */}
                            <div className='FormComponent-Row'>

                                <label className='font-medium'>5. Business Owner <span className='text-[#C4314B]'>*</span></label>
                                <LookUpRHF
                                    fieldId={'businessowner'}
                                    collectionId={'users'}
                                    dataField={'emailaddress'}
                                    placeholder={'Search user by email address'}
                                    required={true}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors.businessowner}
                                    dataFieldSecondary={'givenname'}
                                    dataFieldTertiary={'surname'}
                                    additionalQueries={['usertype', '==', 'lendleaseuser']}
                                    clearErrors={clearErrors}
                                ></LookUpRHF>

                            </div>

                            {/*  ========= 6. IT Owner * ========= */}
                            <div className='FormComponent-Row'>

                                <label className='font-medium'>6. IT Owner <span className='text-[#C4314B]'>*</span></label>
                                <LookUpRHF
                                    fieldId={'itowner'}
                                    collectionId={'users'}
                                    dataField={'emailaddress'}
                                    placeholder={'Search user by email address'}
                                    required={true}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors.itowner}
                                    dataFieldSecondary={'givenname'}
                                    dataFieldTertiary={'surname'}
                                    additionalQueries={['usertype', '==', 'lendleaseuser']}
                                    clearErrors={clearErrors}
                                ></LookUpRHF>

                            </div>

                            {/*  ========= 7. Supported by * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> 7. Supported by <span className='text-[#C4314B]'>*</span></label>
                                <select
                                    className='Input-Field-Select'
                                    {...register(
                                        'supportedby', {
                                    })
                                    } >
                                    <option value='Accenture'>Accenture</option>
                                    <option value='CapGemini'>CapGemini</option>
                                    <option value='Trident'>Trident</option>
                                    <option value='Business'>Business</option>
                                    <option value='Deloitte'>Deloitte</option>
                                    <option value='Other'>Other</option>
                                </select>
                            </div>

                            {/*  ========= 8. Agreements ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> 8. Agreements under this application </label>
                                {/* Search Box */}
                                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#D8D8D8] w-full'>
                                    <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                    <label htmlFor='searchInput'>
                                        <input
                                            id='searchInput'
                                            className='border-none h-[32px] p-0 m-0 outline-none bg-white'
                                            type='text'
                                            placeholder='Search'
                                            autoComplete='no'
                                            onChange={(e) => handleSearch(e.target.value)}
                                        ></input>
                                    </label>
                                </div>

                                {/* Multi Select (with checkbox) */}
                                <div className='flex flex-col h-[200px] p-[10px] border-1 border-solid border-[#D8D8D8] w-full overflow-x-auto'>
                                    {
                                        agreementsFiltered.map((agreementDoc, index) => (

                                            <label key={index} style={{ fontSize: '16px' }} className='Checkbox-Container'>
                                                <input
                                                    type='checkbox'
                                                    value={agreementDoc?.agreementid}
                                                    {
                                                    ...register('agreements')
                                                    }
                                                ></input>
                                                <span className='Checkbox'></span>
                                                {agreementDoc?.poanumber}
                                            </label>

                                        ))
                                    }
                                </div>

                            </div>


                        </div>

                        {/*  ========= Button Container ========= */}
                        <div className='flex flex-row gap-2 border-solid border-t-2 pt-3 mt-3'>
                            {/* Submit */}
                            <button
                                className='Primary-Button whitespace-nowrap'
                                disabled={false}
                                type='submit'
                                value='submit'
                            >
                                Submit
                            </button>

                            {/* Cancel */}
                            <button className='Secondary-Button whitespace-nowrap' onClick={() => {

                                // close side pane
                                reset();
                                setAddApplication(false);

                            }}>Cancel</button>

                        </div>

                    </form>

                    {errors.applications && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.applications?.message}</p>}

                </dialog>
            </div>
        </>
    )
}

