//================================================================
//  Component: Edit Applications in Agreement
//================================================================

//  Purpose: This pane allows the user to edit the list of applications linked to an agreement

//  Properties:
//    - agreement = {useState, stores the agreement document}
//    - setAgreementPane = {useState, used to toggle visibility of the side pane}
//    - application = {object, contains all applications}
//    - setPageStatus = {useState, refreshes the page contents after editing}

//  Example:
//    <EditApplicationsInAgreement
//      agreement={agreement}
//      setAgreementPane={setAgreementPane}
//      application={application}
//      setPageStatus={setPageStatus}
//    ></EditApplicationsInAgreement>    

//================================================================

//Libraries
import React, { useReducer, useState, useEffect, useContext } from 'react';

//Contexts
import { SetToast } from '../../../../Library/GlobalContexts';

//Components
import StatusLabel from '../../../../Components/StatusLabel/StatusLabel';
import ExportToCSV from '../../../../Components/ExportToCSV/ExportToCSV';
import Tooltip from '../../../../Components/Tooltip/Tooltip';

//Functions
import GetCollection from '../../../../Library/GetCollection';
import AddRemoveDocumentArray from '../../../../Library/AddRemoveDocumentArray';

//Images
import IconSearch from '../../../../Components/Images/Icon_Search_Grey.svg';
import InfoIcon from '../../../../Components/Images/Icon_Info_Blue.svg';
import Add from '../../../../Components/Images/Icon_Add_Teal.svg';
import AddDisabled from '../../../../Components/Images/Icon_AddDisabled_Teal.svg'
import ClearSearch from '../../../../Components/Images/Icon_Clear_Grey.svg';
import LoadingIcon from '../../../../Components/Images/Image_Loading_Ripple.svg';


export default function EditApplicationsInAgreement({
    agreement,
    setAgreementPane,
    applications,
    setPageStatus
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store details about the applications
    const [applicationData, setApplicationData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'allApps': [],                      // All apps from the 'applications' collection
            'linkedApps': [],                   // Apps linked to this agreement
            'filteredApps': [],                 // List of apps filtered by search
            'addedApps': [],                    // Apps added to the agreement
            'removedApps': [],                  // Apps removed from the agreement

            // Add Applications Search
            'addAppsSearchInput': '',
            'addAppsSearchResults': [],

            // Table Search
            'tableSearchInput': ''
        }
    );

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to determine the status of the pane > 'pending', 'onload'
    const [paneStatus, setPaneStatus] = useState('pending');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    //  Search for existing applications to add to the agreement
    const handleAllAppsSearch = (value) => {

        // Search by application name
        const searchResults = applicationData?.allApps?.filter((object) =>
            object?.applicationname?.toLowerCase().includes(value.toLowerCase())

        );

        // Turn Dropdown on if field is not empty
        setApplicationData({ 
            'addAppsSearchResults': searchResults
        });

    };

    // Used to search the linked apps table
    const handleTableSearch = (value) => {

        setApplicationData({
            'tableSearchInput': value
        });

        // Reset the Filter
        if (value.length === 0) {
            setApplicationData({
                'filteredApps': applicationData?.linkedApps
            })
        };

        // Search Filter
        //  1. Application Name
        //  2. Vendor Name
        const searchResults = applicationData?.linkedApps?.filter((object) =>
            object?.applicationname?.toLowerCase().includes(value.toLowerCase()) ||
            object?.vendor?.vendorname?.toLowerCase().includes(value.toLowerCase()) 
        );

        setApplicationData({
            'filteredApps': searchResults
        });

    }

    // Submit Handler
    const handleSubmit = () => {
        
        const addRemovePromises = [];

        // Show pending toast
        setToast({
            'type': 'pending',
            'message': 'Saving changes',
        });

        // Loop through ADDED apps > Update Firestore
        applicationData?.addedApps?.forEach((application) => {

            const agreementObj = {
                'agreementid': agreement?.agreementid,
                'poanumber': agreement?.poanumber            
            }

            addRemovePromises.push(
                AddRemoveDocumentArray('applications', application?.applicationid, 'agreements', agreementObj, 'add'), 
            );

        })

        // Loop through REMOVED apps > Update Firestore
        applicationData?.removedApps?.forEach((application) => {

            const agreementObj = {
                'agreementid': agreement?.agreementid,
                'poanumber': agreement?.poanumber            
            }

            addRemovePromises.push(
                AddRemoveDocumentArray('applications', application?.applicationid, 'agreements', agreementObj, 'remove'), 
            );

        })

        Promise.all(addRemovePromises)
        .then(() => {

            // Show Success Toast
            setToast(
                {
                    'type': 'success',
                    'message': 'Agreement saved'
                }
            );

            // Reset input fields and Close pane
            setPaneStatus('pending');
            setAgreementPane(undefined);
            setApplicationData({
                'allApps': [],
                'linkedApps': [],
                'filteredApps': [],
                'addedApps': [],  
                'removedApps': [],
    
                // Add Applications Search
                'addAppsSearchInput': '',
                'addAppsSearchResults': [],
    
                // Table Search
                'tableSearchInput': ''
            });

            // Reload the page/table
            setPageStatus('pending');

        })
        .catch((error) => {

            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to save agreement',
            });


        });

    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Onload
    // - Get all applications
    useEffect(() => {

        // Close the pane if there are issues
        if (agreement === undefined) return;
        if (paneStatus === 'onload') return;

        // Set the applications
        setApplicationData({
            'linkedApps': applications,
            'filteredApps': applications
        });

        GetCollection('applications')
        .then((document) => {

            setApplicationData({ 'allApps': document });
            setPaneStatus('onload');

        })
        .catch((error) => {

            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed on load',
            });

        });

    // eslint-disable-next-line
    }, [agreement]);


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (agreement === undefined) return null;

    //====================================================
    //  Pending
    //====================================================

    if (paneStatus === 'pending') {
        return (
            <div className='Pane-Background'>
                <dialog className='Application-Details-Pane-Container flex flex-col gap-2 pb-0 items-center'>

                    <img className='w-[200px] self-center' alt='loading-circle-icon' src={LoadingIcon}></img>

                    <button className='Primary-Button w-fit self-center' onClick={() => {

                        setAgreementPane(undefined);
                        setPaneStatus('pending');

                    }}>
                        Cancel
                    </button>

                </dialog >
            </div>
        )
    }

    //====================================================
    //  Onload
    //====================================================

    return (
        <div className='Pane-Background'>
            <dialog className='Application-Details-Pane-Container flex flex-col gap-2 pb-0'>

                {/* ------------------------------------------------------ */}
                {/*  Header Container                                      */}
                {/* ------------------------------------------------------ */}
                
                <div className='flex flex-col'>

                    {/* ------------------------------------------------------ */}
                    {/*  Header                                                */}
                    {/* ------------------------------------------------------ */}
                    
                    <div className='flex flex-col gap-1'>
                        
                        {/* Title */}
                        <h5>Agreement | {agreement?.poanumber}</h5>
                        <span className='text-[#424242] text-sm' hidden={agreement?.vendorname?.length === 0}>
                            {agreement?.vendorname}
                        </span>

                        {/* Border - Bottom */}
                        <hr className='my-2'></hr>

                    </div>

                    {/* ------------------------------------------------------ */}
                    {/*  Add Application Search Bar                            */}
                    {/* ------------------------------------------------------ */}

                    <div className='relative my-[10px]'>

                        {/* Search Bar */}
                        <div className='flex flex-row items-center'>

                            {/* Search Input Field */}
                            <input
                                className={
                                    applicationData?.addAppsSearchInput?.length > 0 ?
                                    'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] focus:outline-none'
                                    :
                                    'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] focus:outline-none'
                                }                                    
                                type='text'
                                placeholder='Search existing applications to add to agreement'
                                autoComplete='no'
                                onChange={(evt) => {

                                    const value = evt.target.value;
                                    setApplicationData({ 'addAppsSearchInput': value });

                                    // Hide search
                                    if (value.length === 0) {

                                        return setApplicationData({
                                            'addAppsSearchInput': '',
                                            'addAppsSearchResults': []
                                        })
                
                                    }

                                    // Trigger search
                                    handleAllAppsSearch(value);

                                }}
                                value={applicationData?.addAppsSearchInput}
                            ></input>

                            {/* Clear Search Button */}
                            {
                                applicationData?.addAppsSearchInput?.length > 0 &&
                                <div className='animate-fadein  bg-[#F1F1F1] h-[45px] p-[15px] shadow-md border border-solid border-[#080808] border-l-[none] rounded-tl-none rounded-br-none rounded-tr-[5px] rounded-bl-none text-center align-center cursor-pointer'>
                                    <img className='self-center' src={ClearSearch} alt='ClearSearch' onClick={() => 
                                        setApplicationData({
                                            'addAppsSearchInput': '',
                                            'addAppsSearchResults': ''
                                        })
                                    }></img>
                                </div>
                            }

                        </div>

                        {/* Search Results */}
                        {
                            applicationData?.addAppsSearchResults?.length > 0 && 
                            <div className='absolute z-10 max-h-[350px] w-full overflow-y-auto bg-white shadow-lg border-[1px] border-[solid] border-[#D2D2D2]'>
                                {
                                    applicationData?.addAppsSearchResults?.map((app, index) => (
                                        <div key={index} className='border-b border-b-[#E1E1E1] last:border-0 hover:bg-[#F0F0F0]'>

                                            {
                                                // Check to see if app is already linked
                                                applicationData?.linkedApps.filter((item) => item?.applicationname === app.applicationname)?.length > 0 ?

                                                // Disabled State
                                                <Tooltip
                                                    message={`This application has already been linked to this agreement.`}
                                                    children={
                                                        <div className='w-full flex flex-row justify-between py-[10px] px-[15px]'>
                                                            <div className='flex flex-col'>
                                                                <p className='m-0 font-medium text-[14px] text-[#C4C4C4] uppercase'>
                                                                    {app?.applicationname}
                                                                </p>
                                                                <p className='m-0 text-[14px] text-[#C4C4C4]'>
                                                                    {app?.vendor?.vendorname}
                                                                </p>
                                                            </div>

                                                            {/* Add Button */}
                                                            <img className='w-[28px] cursor-not-allowed' src={AddDisabled} alt='Add'></img>
                                                        </div>
                                                    }
                                                ></Tooltip>
                                                :

                                                // Active State
                                                <div className='flex flex-row justify-between py-[10px] px-[15px] cursor-pointer'>
                                                    <div className='flex flex-col'>
                                                        <p className='m-0 font-medium text-[14px] text-[#424242] disabled:text-[#C4C4C4] uppercase'>
                                                            {app?.applicationname}
                                                        </p>
                                                        <p className='m-0 text-[14px] text-[#A0A0A0] disabled:text-[#C4C4C4]'>
                                                            {app?.vendor?.vendorname}
                                                        </p>
                                                    </div>

                                                    {/* Add Button */}
                                                    <img className='cursor-pointer w-[28px]' src={Add} alt='Add' onClick={() => {

                                                        setApplicationData({
                                                            'linkedApps': [...applicationData?.linkedApps, app],
                                                            'filteredApps': [...applicationData?.filteredApps, app],
                                                            'addAppsSearchInput': '',
                                                            'addAppsSearchResults': [],
                                                            'addedApps': [...applicationData?.addedApps, app]
                                                        });

                                                    }}></img>

                                                </div>
                                            }

                                        </div>
                                    ))}

                            </div>
                        }

                    </div>

                </div>

                {/* ------------------------------------------------------ */}
                {/*  Body Container                                        */}
                {/* ------------------------------------------------------ */}

                <div className='flex flex-col gap-3 justify-between h-full'>

                    <div className='flex flex-col gap-3'>

                        {/* ------------------------------------------------------ */}
                        {/*  Information Box                                       */}
                        {/* ------------------------------------------------------ */}

                        <div className='Info-Box flex flex-row px-2 py-0 items-center'>
                            <img className='p-2 self-center' alt='Info-Icon' src={InfoIcon} />
                            <label className='p-1' >To add new applications to the inventory, click <a href='/inventory?view=applications' target='_blank'> here</a>.</label>
                        </div>

                        {/* ------------------------------------------------------ */}
                        {/*  Table                                                 */}
                        {/* ------------------------------------------------------ */}

                        <div className='Table-Container overflow-hidden'>

                            {/* ======== Sorting and Filters ========== */}
                            <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                                <label className='font-semibold self-center'>
                                    Total: {applicationData?.filteredApps?.length} of {applicationData?.linkedApps?.length}
                                </label>

                                <div className='flex flex-row gap-2'>

                                    {/* ======== Search Bar ======== */}
                                    <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[400px]'>
                                        <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                        <input
                                            className='border-none h-[36px] w-full p-0 m-0 outline-none bg-white'
                                            type='text'
                                            placeholder='Search applications in this agreement'
                                            onChange={(e) => handleTableSearch(e.target.value)}
                                            autoComplete='no'
                                            value={applicationData?.tableSearchInput}
                                        ></input>
                                    </div>

                                    {/* Download Report */}
                                    <ExportToCSV
                                        filename={`${agreement?.vendor?.vendorname}-Applications-${Date.now()}`}
                                        data={applicationData?.filteredApps}
                                    ></ExportToCSV>

                                </div>

                            </div>
                        
                            {/* ============= Table ============== */}
                            <div className='overflow-auto h-full'>
                                <table className='w-full max-h-96 overflow-y-scroll'>
                                    <thead>
                                        <tr className='sticky top-0 drop-shadow-md bg-white border-b-[#D8D8D8] border-solid border-b-2'>
                                            <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Application Name</th>
                                            <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Vendor</th>
                                            <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Business Owner</th>
                                            <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>IT Owner</th>
                                            <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Status</th>
                                            <th className='py-[15px] px-[18px] font-semibold text-[#212529]'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // No applications found
                                            applicationData?.filteredApps?.length === 0 ?
                                                (
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className='text-center p-[2%]'>
                                                                No applications found.
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) :
                                                // Map in each registered application
                                                applicationData?.filteredApps?.map((app, index) => (
                                                    <tr key={index} className='border-b-[#C8C8C9] bg-[#FFFFFF] border-solid border-b last:border-0'>

                                                        {/* Application Name */}
                                                        <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                            {app?.applicationname}
                                                        </td>

                                                        {/* Vendor */}
                                                        <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                            {app?.vendor?.vendorname}
                                                        </td>

                                                        {/* Business Owner */}
                                                        <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                            {app?.businessowner[0] ? app?.businessowner[0] : '-'}
                                                        </td>

                                                        {/* IT Owner */}
                                                        <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                            {app?.itowner[0] ? app?.itowner[0] : '-'}
                                                        </td>

                                                        {/* Status */}
                                                        <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                            <StatusLabel status={app?.applicationstatus}></StatusLabel>
                                                        </td>
                                                        
                                                        {/* Remove Button */}
                                                        <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'> 
                                                            <div className='Cancel-Icon cursor-pointer w-[28] self-center' onClick={() => {

                                                                setApplicationData({
                                                                    'linkedApps': applicationData?.linkedApps?.filter((object) => object !== app),
                                                                    'filteredApps': applicationData?.linkedApps?.filter((object) => object !== app),
                                                                    'removedApps': [...applicationData?.removedApps, app]
                                                                });

                                                            }}></div>
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>

                    {/* ------------------------------------------------------ */}
                    {/*  Buttons Container                                     */}
                    {/* ------------------------------------------------------ */}

                    <div className='flex flex-row my-3 gap-2'>

                        {/* Submit */}
                        <button className='Primary-Button' onClick={() => handleSubmit()}>
                            Save
                        </button>

                        {/* Cancel */}
                        <button className='Secondary-Button' onClick={() => {

                            setApplicationData({
                                'allApps': [],
                                'linkedApps': [],
                                'filteredApps': [],
                                'addedApps': [],
                                'removedApps': [],
                    
                                // Add Applications Search
                                'addAppsSearchInput': '',
                                'addAppsSearchResults': [],
                    
                                // Table Search
                                'tableSearchInput': ''
                            });

                            // Close Side Pane
                            setAgreementPane(undefined);
                            setPaneStatus('pending');
                            
                        }}> 
                            Cancel
                        </button>

                    </div>

                </div>

            </dialog>
        </div>

    )
}
