//================================================================
//  Tab: Applications
//================================================================

//  Purpose: This tab displays all catalogue items for Applications with the status of 'Active'

//  Example:
//    <Applications></Applications>    

//================================================================

//Libraries
import { useContext, useState, useEffect } from 'react';

//Contexts
import { SetSortFilters } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';
import PublishToITStore from './SidePanes/PublishToITStore';
import TableHeader from '../../../Components/SortFilter/TableHeader';
import FilterTags from '../../../Components/SortFilter/FilterTags';
import FilterByPane from '../../../Components/SortFilter/FilterByPane';

//Functions
import QueryListener from '../../../Library/QueryListener';

//Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';


export default function Applications() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------
  const setSortFilters = useContext(SetSortFilters);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Search Input
  const [searchInput, setSearchInput] = useState('');

  // Used to store all applications
  const [applications, setApplications] = useState([]);
  const [applicationsFiltered, setApplicationsFiltered] = useState([]);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------ 

  // Handles search function
  function handleSearch(value) {

    setSearchInput(value);

    // Clear ALL filters & sorting
    if (value.length > 0 && value.length < 2) {

      setSortFilters({

        // Sorting
        'sortBy': '', // vendor.vendorname
        'sortDirection': '', // ascending & descending
        'sortDataType': '', // '' or 'number'

        // Filtering (Map of objects)
        'filters': {},

        // Sidepane for selecting filterby content
        'filterByPane': {
          'columnname': '', // Vendor Name
          'columnid': '', // vendor.vendorname
        }

      });

    }

    // Reset the Filter
    if (value.length === 0) {

      setApplicationsFiltered(applications);

    };

    // Search Filter
    //  1. applicationname
    //  2. vendor.vendorname
    //  3. subscribed
    const searchResults = applications.filter((object) =>
      object?.name?.toLowerCase().includes(value.toLowerCase()) ||
      object?.vendor?.vendorname?.toLowerCase().includes(value.toLowerCase()) ||
      object?.subscribed?.toString().toLowerCase().includes(value.toLowerCase())
    );

    // Save the filtered search results to the useState
    setApplicationsFiltered(searchResults);

  };

  // Create data format for CSV Export
  function getDataObject(applications) {
    const data = [];

    applications.forEach((app) => {

      data.push({
        'Application Name': app.name,
        'Description': app?.description,
        'Vendor': app?.vendor?.vendorname,
        'Pricing': ` ${app?.pricing?.amount} / ${app?.pricing?.frequency}`,
        'Currency': app?.pricing?.currency,
        'Subscribers': app?.subscribed,
      });
    })

    return data;
  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all applications with the status of 'Active'
  useEffect(() => {

    function onLoad(documents) {

      setApplications(documents);
      setApplicationsFiltered(documents);
      setSearchInput('');

      setPageStatus('onload');

    }

    function onChange(documents) {

      setApplications(documents);
      setSearchInput('');

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = QueryListener('catalogueitems', [], onLoad, onChange, onError);

    return () => {
      unsubscribe();
    };

  }, []);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    <PageComponent
      status={pageStatus}
      body={
        <div className='flex flex-col px-[15px] gap-[20px] w-[calc(100vw-40px)] max-h-[calc(100vh-330px)] overflow-hidden'>

          <div className='flex flex-row justify-end items-center gap-3'>
            <div className='flex flex-row items-center gap-2'>

              {/* Search Bar */}
              <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#D8D8D8] w-[300px]'>
                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                <label htmlFor='searchInput'>
                  <input
                    id='searchInput'
                    className='border-none h-[40px] p-0 m-0 outline-none'
                    type='text'
                    placeholder='Search'
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchInput}
                  ></input>
                </label>
              </div>

              {/* Publish to IT Store */}
              <PublishToITStore applications={applications}></PublishToITStore>

              {/* Download IT Report */}
              <ExportToCSV
                filename={`applicationcatalogue-${Date.now()}`}
                data={getDataObject(applicationsFiltered)}
              ></ExportToCSV>

            </div>
          </div>

          {/* ----------------------------------- */}
          {/*  Table Container                    */}
          {/* ----------------------------------- */}

          <FilterTags
            tableArray={applications}
            setFilteredTableArray={setApplicationsFiltered}
          ></FilterTags>

          <div className='Table-Container flex flex-col w-full h-full !min-h-[332px] overflow-hidden'>

            {/* ======== Table Filters ========== */}
            <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

              <label className='font-semibold self-center'>
                Total: {applicationsFiltered.length} of {applications.length}
              </label>

            </div>

            {/* Makes the table scrollable */}
            <div className='w-full overflow-auto'>

              <FilterByPane
                tableArray={applications}
              ></FilterByPane>

              <table className='w-full min-h-[350px]'>
                <thead>
                  <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b z-10 box-border overflow-visible'>

                    {/* Icon */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Icon</th>

                    {/* Application Name (Sort/Filter) */}
                    <TableHeader
                      column='Application Name'
                      styleInput={{ minWidth: '120px' }}
                      objectKey='name'
                      sticky={true}
                      setSearchInput={setSearchInput}
                    ></TableHeader>

                    {/* Description */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-wrap'>Description</th>

                    {/* Vendor  */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Vendor</th>

                    {/* Pricing */}
                    <TableHeader
                      column='Pricing'
                      objectKey='pricing.amount'
                      setSearchInput={setSearchInput}
                    ></TableHeader>

                    {/* Currency */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Currency</th>

                  </tr>
                </thead>
                <tbody>
                  {

                    // No applications found
                    applicationsFiltered.length === 0 ?
                      (
                        <tr>
                          <td colSpan={6}>
                            <div className='text-center p-[2%]'>
                              No applications found.
                            </div>
                          </td>
                        </tr>
                      )
                      :
                      (
                        // Map in each commercial application
                        applicationsFiltered.map((application, index) => (
                          <tr key={index} className='border-b-[#D8D8D8] border-solid border-b hover:bg-[#F0F7F7]'>

                            {/* Icon */}
                            <td className='p-[15px] border-solid border-r' >
                              <div className='flex flex-col items-center justify-center h-full'>
                                <img className='w-[60px] h-[60px]' src={application?.iconurl} alt='Application Icon'></img>
                              </div>
                            </td>

                            {/* Application Name */}
                            <td className='p-[15px] border-solid border-r'>
                              {application?.name}
                            </td>

                            {/* Description */}
                            <td className='p-[15px] border-solid border-r max-w-[40ch] max-h-px overflow-hidden whitespace-wrap text-ellipsis'>
                              {application?.description}
                            </td>

                            {/* Vendor Name */}
                            <td className='p-[15px] border-solid border-r'>
                              {application.vendor.vendorname}
                            </td>

                            {/* Pricing */}
                            <td className='p-[15px] border-solid border-r'>
                              {application?.pricing?.amount} / {application?.pricing?.frequency}
                            </td>

                            {/* Currency */}
                            <td className='p-[15px] border-solid border-r'>
                              {application?.pricing?.currency}
                            </td>

                          </tr>

                        ))
                      )
                  }
                </tbody>
              </table>

            </div>

          </div>

        </div>
      }

    ></PageComponent>
  )
}
