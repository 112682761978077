//================================================================
//  Component: Label List
//================================================================

//  Purpose: This is a list component displaying values with
//  a different format depending on how many items are in the list.

//  Properties:
//    - index = Number
//    - objectList = [Array]
//    - object = {object}
//    - setSidePane = useState
//    - directory = Directory to redirect user on click (optional) 'string'
//    - idList = id for redirection (optional) [Array]

//  LabelList:
//    <LabelList
//      index={index}
//      objectList={application?.subscribed}
//      object={application}
//      setSidePane={setApplicationPane}
//      directory={'/inventory/application/'} 
//      idList={applicationDocs.map(app => app.applicationid)}
//    ></LabelList>    

//================================================================

//Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts

//Components

//Functions

//Images
import EditIcon from '../Images/Icon_Edit_Teal.svg';

//CSS


export default function LabelList({
    index,
    objectList,
    object,
    setSidePane,
    directory,
    idList,
}) {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

    const navigate = useNavigate()

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div key={index}>
            {
                // More than 2 objects
                objectList?.length > 2 ? 
                (
                    <div className='flex flex-col'>
                        <div className='flex flex-row justify-between gap-2'>

                            {/* Subscriber List */}
                            <div className='flex flex-col gap-1'>

                                <label className={`${setSidePane === undefined ? 'max-w-[250px]' : 'max-w-[150px]'} whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-medium underline px-[10px] py-[6px] rounded-md overflow-hidden text-ellipsis w-fit cursor-pointer`} onClick={() => directory ? navigate(directory+idList[0]) : {}}>{objectList[0]}</label>
                                <label className={`${setSidePane === undefined ? 'max-w-[250px]' : 'max-w-[150px]'} whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-medium underline px-[10px] py-[6px] rounded-md overflow-hidden text-ellipsis w-fit cursor-pointer`} onClick={() => directory ? navigate(directory+idList[1]) : {}}>{objectList[1]}</label>

                            </div>

                            {/* Edit Icon */}
                            <div className=' flex flex-col justify-between' hidden={setSidePane === undefined}>
                                <img className='min-w-[20px] self-end cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setSidePane(object)}></img>
                            </div>

                        </div>

                        {/* Total Count */}
                        <div>
                            <label className='text-right font-semibold mt-[18px] text-[#212529] float-right'>... 2 of {objectList.length}</label>
                        </div>
                    </div>
                ) 
                :

                // Less than 2 objects
                objectList?.length > 0 ? 
                (

                    <div className='flex flex-row justify-between gap-2 top-0'>

                        {/* Item List */}
                        <div className='flex flex-col gap-1'>
                            {
                                objectList.map((obj, index) => (
                                    <label key={index} className={`${setSidePane === undefined ? 'max-w-[250px]' : 'max-w-[150px]'} whitespace-nowrap text-[#00A7A4] bg-[#ECFCFC] font-medium underline px-[10px] py-[6px] rounded-md overflow-hidden text-ellipsis w-fit cursor-pointer`} onClick={() => directory ? navigate(directory+idList[index]): {}}>
                                        {obj}
                                    </label>
                                ))
                            }
                        </div>

                        {/* Edit Icon */}
                        <img className='min-w-[20px] self-start cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setSidePane(object)} hidden={setSidePane === undefined}></img>

                    </div>

                ) 
                :

                // No objectList -> Just show edit icon
                (
                    <div className='flex flex-row justify-between'>
                        <label className='bg-[#F5F5F5] round py-[6px] px-[12px] mx-auto'>N/A</label>
                        <div className='flex justify-end' hidden={setSidePane === undefined}>
                            <img className='min-w-[20px] self-start cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setSidePane(object)}></img>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
