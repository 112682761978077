  //================================================================
//  Tab: Overview
//================================================================

//  Purpose: This tab displays all 'Overview' of an Application

//  Example:
//    <Overview></Overview>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';
import TasksPane from '../../../../../Components/TasksPane/TasksPane';
import LabelList from '../../../../../Components/LabelList/LabelList';
import EditApplicationsInAgreement from '../../../Tabs/SidePanes/EditApplicationsInAgreement';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';
import GetDocument from '../../../../../Library/GetDocument';
import GetCollection from '../../../../../Library/GetCollection';

//Images
import EditIcon from '../../../../../Components/Images/Icon_Edit_Teal.svg';


export default function Overview() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');
  
    // Holds the application document from 
    const [agreementDoc, setAgreementDoc] = useState();
    const [vendorDoc, setVendorDoc] = useState();
    const [applicationDocs, setApplicationDocs] = useState([]);

    // Open & Close the side panes
    const [taskPane, setTaskPane] = useState(undefined);
    const [agreementPane, setAgreementPane] = useState(undefined);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Query Listener
    // - Get Firestore document
    useEffect(() => {

      const agreementid = params.id;

      if (agreementid === '') return;
      if (agreementid === null) return;

      function onLoadChange(document){  

        setAgreementDoc(document);

        // Get vendor information
        if (document?.vendor?.vendorid === '') return;
        if (document?.vendor?.vendorid === undefined) return;

        GetDocument('vendors', document?.vendor?.vendorid)
        .then((doc) => {

          setVendorDoc(doc);

          // Get all applications 
          GetCollection('applications')
          .then((apps) => {

            // Then filter them down to those that are linked to this agreement
            const x = [];
            apps.forEach(app => {

              let found = app.agreements?.find(obj => obj.agreementid === agreementid);

              if (found) x.push(app);

            });

            setApplicationDocs(x);

            setPageStatus('onload');

          });

        })
        .catch((error) => {

          console.log(error);
          setPageStatus('error-fatal');
      
        });

      }
      
      function onError(error){

        console.log(error);
        setPageStatus('error-fatal');
      
      }
      
      const unsubscribe = DocumentListener('agreements', agreementid, onLoadChange, onLoadChange, onError);
      
      return () =>{
        unsubscribe();
      };
        
    // eslint-disable-next-line
    }, [getUser, pageStatus]);
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <>

            {/* ----------------------------------- */}
            {/*  Form                               */}
            {/* ----------------------------------- */}

            <div className='flex justify-between'>

              {/* ----------------------------------- */}
              {/*  Edit Form                          */}
              {/* ----------------------------------- */}

              <div className='flex flex-col w-[50%] px-[20px]'>

                {/* PoA Number */}
                <LiveField
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'poanumber'}
                  label={<label className='font-medium'>PoA Number</label>}
                  defaultValue={agreementDoc?.poanumber}
                  type={'text'}
                  disabled={true}
                ></LiveField>    

                {/* Agreement Status */}
                <LiveField
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'status'}
                  label={<label className='font-medium'>Agreement Status</label>}
                  defaultValue={agreementDoc?.status}
                  type={'choice'}
                  options={[
                    'Active',
                    'Draft',
                    'Cancelled',
                    'Expired',
                  ]}
                ></LiveField>    

                {/* Short Description */}
                <LiveField
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'shortdescription'}
                  label={<label className='font-medium'>Short Description</label>}
                  defaultValue={agreementDoc?.shortdescription}
                  type={'multiline'}
                  disabled={false}
                  placeholder={'Enter description'}
                ></LiveField>   

                {/* Vendor Contact Name */}
                <LiveField
                  type={'lookup'}
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'vendor'}
                  label={<label className='font-medium'> Vendor Name </label>}
                  defaultValue={agreementDoc?.vendor?.vendorname}
                  placeholder={'Select a vendor'}
                  dataFieldCollectionid={'vendors'}
                  dataFieldPrimary={'vendorname'}
                  dataFieldSecondary={'tradingas'}
                  dataFieldTertiary={'vendorid'}
                  createNewHTML={<span className='font-normal text-[#A0A0A0]'>Can't find what you're looking for? Click <a href='http://inventory.store.lendlease.com/inventory?view=vendors' target='_blank' rel='noopener noreferrer'>here</a>.</span>}
                ></LiveField> 

                {/* Vendor Web Address */}
                <LiveField
                  collectionid={'vendors'}
                  documentid={vendorDoc?.vendorid}
                  fieldpath={'website'}
                  label={<label className='font-medium'>Vendor Web Address</label>}
                  defaultValue={vendorDoc?.website}
                  type={'text'}
                  disabled={true}
                ></LiveField>  

                {/* Vendor Contact Phone */}
                <LiveField
                  collectionid={'vendors'}
                  documentid={vendorDoc?.vendorid}
                  fieldpath={'contactnumber'}
                  label={<label className='font-medium'>Vendor Contact Phone</label>}
                  defaultValue={vendorDoc?.contactnumber}
                  type={'text'}
                  disabled={true}
                ></LiveField>  

                {/* Vendor Contact Email */}
                <LiveField
                  collectionid={'agreements'}
                  documentid={vendorDoc?.vendorid}
                  fieldpath={'contactemail'}
                  label={<label className='font-medium'>Vendor Contact Email</label>}
                  defaultValue={vendorDoc?.contactemail}
                  type={'text'}
                  disabled={true}
                ></LiveField>  

                {/* Expiry Date */}
                <LiveField
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'expirydate'}
                  label={<label className='font-medium'>Expiry Date</label>}
                  defaultValue={agreementDoc?.expirydate}
                  type={'date-string'}
                  disabled={false}
                ></LiveField> 
                
                {/* Commercial Scope */}
                <LiveField
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'commercialscope'}
                  label={<label className='font-medium'>Commercial Scope</label>}
                  defaultValue={agreementDoc?.commercialscope}
                  type={'multiline'}
                  disabled={false}
                  placeholder={'Enter commercial scope'}
                ></LiveField>   

                {/* Sponsor Name */}
                <LiveField
                  type={'user-search'}
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'sponsorname'}
                  label={<label className='font-medium'>Sponsor Name</label>}
                  defaultValue={agreementDoc?.sponsorname}
                  disabled={false}
                ></LiveField>  

                {/* Buy/Sell Agreement */}
                <LiveField
                  type={'choice'}
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'buysellagreement'}
                  label={<label className='font-medium'> Buy / Sell Agreement </label>}
                  defaultValue={agreementDoc?.buysellagreement}
                  options={[
                    'Buy',
                    'Sell',
                    'N/A'
                  ]}
                  disabled={false}
                ></LiveField> 

                {/* Agreement Type */}
                <LiveField
                  type={'choice'}
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'agreementtype'}
                  label={<label className='font-medium'>Agreement Type</label>}
                  defaultValue={agreementDoc?.agreementtype}
                  disabled={false}
                  options={[
                    'Consultancy Services Agreement (CSA)',
                    'Hardware Order',
                    'Letter of Intent (LoI)',
                    'Master Services Agreement (MSA)',
                    'Non-Disclosure Agreement (NDA)',
                    'Novation/Assignment Agreement',
                    'Service Order (SO)',
                    'Software License',
                    'Subscription',
                    'Supply Agreement',
                    'Variation'
                  ]}
                ></LiveField> 

                {/* Last Modified By */}
                <LiveField
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'lastmodifiedby'}
                  label={<label className='font-medium'>Last Modified By</label>}
                  defaultValue={agreementDoc?.lastmodifiedby?.email}
                  type={'text'}
                  disabled={true}
                ></LiveField>  

                {/* Last Modified Date */}
                <LiveField
                  collectionid={'agreements'}
                  documentid={agreementDoc?.agreementid}
                  fieldpath={'lastmodifieddate'}
                  label={<label className='font-medium'>Last Modified Date</label>}
                  defaultValue={agreementDoc?.lastmodifieddate}
                  type={'timestamp'}
                  disabled={true}
                ></LiveField> 

              </div>

              {/* ----------------------------------- */}
              {/*  Tiles                              */}
              {/* ----------------------------------- */}

              <div className='flex flex-col gap-3'>

               {/* Applications */}
              <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                  <label className='font-medium'> Applications</label>
                  <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setAgreementPane(agreementDoc)}></img>
                </div>

                <hr className='m-0 border-1 border-[#424242]'></hr>

                <div className='py-[15px] px-[20px]'>
                  <LabelList objectList={applicationDocs.map(app => app.applicationname)} object={agreementDoc} index={0} directory={'/inventory/application/'} idList={applicationDocs.map(app => app.applicationid)}></LabelList>
                </div>

                </div>

                {/* Linked Requests */}
                <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                  <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                    <label className='font-medium'> Linked Requests</label>
                    <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setTaskPane(agreementDoc)}></img>
                  </div>

                  <hr className='m-0 border-1 border-[#424242]'></hr>

                  <div className='min-h-[40px] max-h-[160px] overflow-y-auto'>
                    <label className='py-[15px] px-[20px]'>{agreementDoc?.requestid.filter((value) => value !== '' && value !== null).length} linked requests</label>
                  </div>

                </div>
                                
              </div>

            </div>

            {/* ----------------------------------- */}
            {/*  Side Panes                         */}
            {/* ----------------------------------- */}

            <TasksPane
              document={taskPane}
              setDocument={setTaskPane}
            ></TasksPane>

            <EditApplicationsInAgreement 
              agreement={agreementPane} 
              setAgreementPane={setAgreementPane} 
              applications={applicationDocs} 
              setPageStatus={setPageStatus}
            ></EditApplicationsInAgreement>


          </>
        }
      ></PageComponent>
    )
}
