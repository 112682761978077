//================================================================
//  Page: Catalogue
//================================================================

//  Purpose: This page shows all items in the Catalogue

//  Example:
//    <Catalogue></Catalogue>    

//================================================================


// Libraries
import React from 'react';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';

// Tabs
import ApplicationsTab from './Tabs/Applications';

// Functions

// Images

// CSS
import './Catalogue.css';


export default function Catalogue() {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      header={
        <div className='Page-Header-Container py-4'>
          <p className='font-medium text-[20px] mb-2'> Catalogue </p>
          <p className='text-[16px] mb-0'>
            List of applications and hardware available to be purchased in the IT Store.
          </p>
        </div>
      }
      body={
        <TabView
          defaultView={1}

          //------------------------------------------------------
          // Applications
          //------------------------------------------------------

          oneTitle={'Applications'}
          oneRoute={`/catalogue?view=applications`}
          oneContent={
            <ApplicationsTab></ApplicationsTab>
          }

        ></TabView>
      }
    ></PageComponent>
  )
}
